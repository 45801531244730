import React, {useEffect, useState,} from "react";
import {Button, Col, Row, Container} from "react-bootstrap";
import {ColorList, productlistHome, productlistGolf} from "../app/constant";
// import HomeGym from "../assets/image/roomdecorationpic/HomeGym.png";
import HomeGallery from "../assets/image/roomdecorationpic/HomeGallery.png";
import HomeOffice from "../assets/image/roomdecorationpic/HomeOffice.png";
import HomeGolf from "../assets/image/roomdecorationpic/HomeGolf.png";
import Stack from "../assets/image/selectproduct/hometheater/Stack.png";
import Stackglof from "../assets/image/selectproduct/golfsimulator/Stack.svg";
import BasketWeave from "../assets/image/selectproduct/HomeOffice/Basket-Weave.png";
import FirstImg from "../assets/image/colors/1.jpeg";
import HomeTheater from "./HomeTheater";
import GolfSimulator from "./GolfSimulator";
import HomeGym from "./HomeGym";


export const Walls = (props) => {
    const slug = props.slug;
    const slug_data = slug === "homeoffice" ? "25%" : "86px 175px"
    return (
        <div className="category_img" style={{
            // backgroundImage: `linear-gradient( rgba(${props.frontimage},0.8), rgba(${props.frontimage},0.8)), url("${props.myselectproduct}")`,
            backgroundImage: `url("${props.frontimage}`,
            backgroundRepeat: "repeat",
            backgroundSize: `${slug_data}`
        }}>
            {/*{slug === 'hometheater' ? <img src={HomeGallery} alt="Gym-img" className="img-fluid main_img"/> : ''}*/}
            {/*{slug === 'homegym' ? <img src={HomeGym} alt="Gym-img" className="img-fluid main_img"/> : ''}*/}
            {slug === 'homeoffice' ? <img src={HomeOffice} alt="Gym-img" className="img-fluid main_img"/> : ''}
            {/*{slug === 'homegolf' ? <img src={HomeGolf} alt="Gym-img" className="img-fluid main_img"/> : ''}*/}

            <div className="pattern_change" style={{
                backgroundImage: `url("${props.myselectproduct}`,
                backgroundRepeat: "repeat",
                backgroundSize: `${slug_data}`,
            }}>

            </div>


        </div>
    );
};

const Customizewall = (props) => {

    const slug = props.slug
    const [FrontColors, setFrontColors] = useState(FirstImg);
    const [Sproduct, setproduct] = useState(slug === "homeoffice" ? BasketWeave :slug === "homegolf" ? Stackglof : Stack);

    const colorFix = (image) => {
        setFrontColors(image);
    };
    const imageFix = (image) => {
        setproduct(image);
    };
    useEffect(() => {
        if (slug === "homeoffice") {
            setproduct(BasketWeave)
        } else if (slug === "homegolf"){
            setproduct(Stackglof)
        }else {
            setproduct(Stack)
        }
    }, [slug])


    return (
        <Container className="custom_Sound_Pannel">

            <Row>
                <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                    {slug === 'homegym' ?
                        <div className='main_div'>
                            <h3>Home Gym Setup </h3>
                            <HomeGym slug={slug} frontimage={FrontColors} myselectproduct={Sproduct}/>
                        </div> : ''}


                    {slug === 'homeoffice' ?
                        <div className='main_div home_oofice'>
                            <h3>Home Office </h3>
                            <Walls slug={slug} frontimage={FrontColors} myselectproduct={Sproduct}/>
                        </div> : ''}

                    {slug === 'homegolf' ?
                        <div className='main_div'>
                            <h3>Home Golf Simulator</h3>
                            <GolfSimulator slug={slug} frontimage={FrontColors} myselectproduct={Sproduct}/>
                        </div> : ''}
                    {slug === 'hometheater' ?
                        <div className='main_div'>
                            <h3>Home Theater Gallery</h3>
                            <HomeTheater slug={slug} frontimage={FrontColors} myselectproduct={Sproduct}/>
                        </div> : ''}

                </Col>

                <Col xs={12} sm={12} md={6} lg={4} className="offset-lg-1">
                    <div className="Choose_color mt-3">
                        <p>Pick a Color</p>
                        <div className="column_color color_mainbox">
                            <ul>
                                {ColorList.map((color) => (
                                    <li
                                        onClick={() => colorFix(color.image)}>
                                        <img src={color.image}/>

                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {slug !== 'homeoffice' && slug === 'homegolf' ?
                        <div className="column_color select_products">

                            <ul>
                                {productlistGolf.map((img) => (
                                    <li onClick={() => imageFix(img.image)}>
                                        <img src={img.image}/>
                                        <p className="product_name">{img.info}</p>
                                    </li>
                                ))
                                }
                            </ul>

                        </div>
                        : slug === 'hometheater' || slug === 'homegym' ?
                            <div className="column_color select_products">

                                <ul>
                                    {productlistHome.map((img) => (
                                        <li onClick={() => imageFix(img.image)}>
                                            <img src={img.image}/>
                                            <p className="product_name">{img.info}</p>
                                        </li>
                                    ))
                                    }
                                </ul>

                            </div>
                            :
                            ''
                    }

                </Col>
            </Row>
        </Container>
    );
};

export default Customizewall;
