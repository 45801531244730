import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FaEnvelope, FaTwitter } from "react-icons/fa";
import { MdCall, MdLocationOn } from "react-icons/md";
import { ImFacebook, ImYoutube } from "react-icons/im";
import { GrInstagram } from "react-icons/gr";

const Topheader = () => {
  return (
    <div className="myheader">
      <header>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container className="container">
            <Navbar.Brand exact to="/">
              Welcome to Residential Sound Panels{" "}
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#features" className="space-between">
                  <MdCall className="icon" /> (801) 619-7044
                </Nav.Link>
                <Nav.Link href="#pricing" className="space-between">
                  <FaEnvelope className="icon" /> sales@onnitsystems.com
                </Nav.Link>
                <Nav.Link href="#pricing" className="space-between">
                  <MdLocationOn className="icon" /> 775 West Concord Park Dr.
                  Bluffdale, UT 84065
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="#features">
                  <FaTwitter className="icon" />{" "}
                </Nav.Link>
                <Nav.Link href="#pricing">
                  <ImFacebook className="icon" />{" "}
                </Nav.Link>
                <Nav.Link href="#pricing">
                  <GrInstagram className="icon" />
                </Nav.Link>
                <Nav.Link href="#pricing">
                  <ImYoutube className="icon" />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

export default Topheader;
