import Topheader from "../components/Topheader";
import Secondheader from "../components/secondheader";
import Headerimage from "../components/Headerimage";
import header from "../assets/image/bannarimages/header.png";
import contentfirst from "../assets/image/contentimages/1.png";
import Contentandimage from "../components/Contentandimage";
import Smallbanners from "../components/Smallbanners";
import first from "../assets/image/contentimages/small-bannars/1.png";
import second from "../assets/image/contentimages/small-bannars/2.png";
import third from "../assets/image/contentimages/small-bannars/3.png";
import fourth from "../assets/image/contentimages/small-bannars/4.png";
import {Container, Col, Row} from "react-bootstrap";
import Consultation from "../components/Consultation";
import Consultationimg from "../assets/image/bannarimages/b1.png";
import Discoverline from "../components/Discoverline";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";


const Imagelist = [
    {
        id: "1",
        image: first,
        info: "Home Theater",
    },
    {
        id: "2",
        image: second,
        info: "Home Gym Setup",
    },
    {
        id: "3",
        image: third,
        info: "Home Golf Simulator",
    },
    {
        id: "4",
        image: fourth,
        info: "Home Office",
    },
];

const ListItem = () =>
    Imagelist.map((data, index) => {
        return (
            <Col key={data.id} sm={12} xs={12} md={3} lg={3} xl={3} xxl={3}>
                <div>
                    <Smallbanners data={data}/>
                </div>
            </Col>
        );
    });

const Home = () => {
    return (
        <>
            <Topheader/>
            <Secondheader/>
            <Headerimage
                image={header}
                title="Enjoy a premium viewing experience with your friends & family"
            />
            <Contentandimage
                image={contentfirst}
                heading="Fully customizable, felt sound absorbing acoustical wall panels"
                content="This is dummy. It is not meant to be read. It is placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. But then, this is dummy copy. It is not meant to be read. Period."
                buttontext="Request a Quote"
            />
            <Container className="smallbanner">
                <Row>
                    <ListItem/>
                </Row>
            </Container>
            <Consultation
                image={Consultationimg}
                heading="Schedule A Free Consultation"
                title={`We would love to discuss your project.`}
                subtitle={"Please get in touch"}
                btn="Get in Touch"
            />
            <Discoverline/>
            <Container className="smallbanner custom_second">
                <Row>
                    <ListItem/>
                </Row>
            </Container>
            <Testimonials/>
            <Footer/>
        </>
    );
};
export default Home;
