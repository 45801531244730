import React from "react";
import Topheader from "../components/Topheader";
import Secondheader from "../components/secondheader";
import Footer from "../components/Footer";
import LetCustomizeYourWall from "../components/LetCustomizeYourWall";

const LetsCustomizeYourWall = () => {
    return (
        <>
            <Topheader/>
            <Secondheader/>
            <LetCustomizeYourWall/>
            <Footer/>
        </>
    );
};

export default LetsCustomizeYourWall;
