import React from 'react'
import { Button, Container } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

const Discoverline = () => {
    return (
        <div className='discover'>
            <div className='container'>
                <Row>
                    <Col className='side_text' sm={12} xs={12} md={9} lg={9} xl={9} xxl={9}>
                        <p>
                            Discover our project portfolio
                        </p>
                    </Col>
                    <Col sm={12} xs={12} md={3} lg={3} xl={3} xxl={3}>
                        <div className='for_center'>
                            <Button className='side_btn'>
                                View All Projects
                                <HiOutlineArrowNarrowRight />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Discoverline