import React from 'react'
import { Container, Button } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

const Contentandimage = (props) => {

  return (
    <div className='contentwithimage'>
      <Container className='container'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}  >
            <div className='side-image'>
              <img className="img-fluid" src={props.image} alt='' />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}  >
            <div className='side-content mt-5'>
              <h1 className='heading_content'>{props.heading}</h1>
              <p className='para_content'>
                {props.content}
              </p>
              <div className='btn_content'>
                <Button>
                  {props.buttontext}
                  <HiOutlineArrowNarrowRight className='icon' />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  )
}

export default Contentandimage