import React, {Component, useEffect, useRef} from "react";
import * as THREE from "three";
import TheaterScreen from '../assets/test/TheaterScreen.png';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

function HomeTheater(props) {

    let ColorChange = props.frontimage;
    let PattrenChange = props.myselectproduct;

    const ref = useRef(null);

    const cearteCanvas = (ColorChange, PattrenChange) => {


        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(73, window.innerWidth / window.innerHeight, 0.1, 1000);
        // var camera = new THREE.OrthographicCamera(  - 2,  2, 2, - 2, 0.1,  1000 );
        // const container = document.getElementById('CanvasFrame');
        const renderer = new THREE.WebGLRenderer({antialias: true});
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        // container.appendChild(renderer.domElement);
        ref.current.appendChild(renderer.domElement);
        scene.background = new THREE.Color(0x00000);




  

        //   Mesh Material

        const texture = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(2, 1);

        });

        // immediately use the texture for material creation
        const WallFrontTexture = new THREE.MeshBasicMaterial({map: texture});
        // material.blending =THREE.MultiplyBlending;
        WallFrontTexture.transparent = true;

        // For wall left side
        const texture2 = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(1, 1);

        });

        // immediately use the texture for material creation
        const WallFrontTexture2 = new THREE.MeshBasicMaterial({
            map: texture2,
            
        });
        // material.blending =THREE.MultiplyBlending;
        WallFrontTexture2.transparent = true;


        const walltexture = new THREE.TextureLoader().load(ColorChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(1,4);

        });
        // immediately use the texture for material creation
        const WallColorMaterial = new THREE.MeshStandardMaterial({
            color:0xDEDEDE,
            map: walltexture,
        emissive:0.1});

        const Rightwalltexture = new THREE.TextureLoader().load(ColorChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(2,4);

        });
        // immediately use the texture for material creation
        const RightWallColorMaterial = new THREE.MeshStandardMaterial({
            color:0xDEDEDE,
            map: Rightwalltexture,
        emissive:0.1});



        const Scenetexture = new THREE.TextureLoader().load(TheaterScreen);
        // immediately use the texture for material creation
        const Scenematerial = new THREE.MeshBasicMaterial({map: Scenetexture});
        Scenematerial.transparent = true;


        //   Mesh Material


        const light = new THREE.AmbientLight( {color:0x404040,intensity:0} ); // soft white light
scene.add( light );

        // GEOMETRY START


        //  Back Wall Geometry for right side wall
        var geometryLateral = new THREE.PlaneGeometry(15.8, 11.2);

        // BACKWALL

        var Backwallright = new THREE.Mesh(geometryLateral, RightWallColorMaterial);
        scene.add(Backwallright);
        Backwallright.position.set(2.45, 11.6, 9);
        Backwallright.rotation.set(0, 10 * (Math.PI / 180), 0);

        // BACKWALL_TEXTURE

        var BackwallTexture = new THREE.Mesh(geometryLateral, WallFrontTexture);
        scene.add(BackwallTexture);
        BackwallTexture.position.set(2.45, 11.6, 9);
        BackwallTexture.rotation.set(0, 10 * (Math.PI / 180), 0);


     

           // RIGHT BACKWALL DOWN-ONE
   
           var Rightdownwall = new THREE.Mesh(geometryLateral, RightWallColorMaterial);
           scene.add(Rightdownwall);
           Rightdownwall.position.set(2.45, 0.401, 9);
           Rightdownwall.rotation.set(0, 10 * (Math.PI / 180), 0);
   
           // BACKWALL_TEXTURE
   
           var RightdownwallTexture = new THREE.Mesh(geometryLateral, WallFrontTexture);
           scene.add(RightdownwallTexture);
           RightdownwallTexture.position.set(2.45, 0.401, 9);
           RightdownwallTexture.rotation.set(0, 10 * (Math.PI / 180), 0);


        //  Back Wall Geometry for left side wall
        var geometryLateral2 = new THREE.PlaneGeometry(9.7, 13.35);

        // BACKWALL

        var Backwallleft = new THREE.Mesh(geometryLateral2, WallColorMaterial);
        scene.add(Backwallleft);
        Backwallleft.position.set(-14, 12.37, 9);
        Backwallleft.rotation.set(0, 10 * (Math.PI / 180), 0);

        // BACKWALL_TEXTURE

        var BackwallTexture2 = new THREE.Mesh(geometryLateral2, WallFrontTexture2);
        scene.add(BackwallTexture2);
        BackwallTexture2.position.set(-14, 12.37, 9);
        BackwallTexture2.rotation.set(0, 10 * (Math.PI / 180), 0);


        // FRONT_SCREEN
        var Scenegeometry = new THREE.PlaneGeometry(30, 16);
        var Scene = new THREE.Mesh(Scenegeometry, Scenematerial);

        scene.add(Scene);
        Scene.position.set(0, 8.960, 15.000);
        Scene.rotation.set(0, 0, -1 * (Math.PI / 180));
        Scene.scale.set(1, 1, 1);
        // Scene.rotation.set(-90*(Math.PI/180), 0, 0)


        // GEOMETRY END


        // LIGHTS

        // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        // directionalLight.position.set(4, 6, 0); //default; directionalLight shining from top
        // directionalLight.castShadow = true;
        // directionalLight.receiveShadow = false; //default
        // scene.add(directionalLight);

        //Create a PointLight and turn on shadows for the light
        // const light = new THREE.PointLight(0xffffff, .3, 100);
        // light.position.set(0, 10, 4);
        // light.castShadow = true; // default false
        // scene.add(light);


        // LIGHTS


        //Set up shadow properties for the light
        // light.shadow.mapSize.width = 512; // default
        // light.shadow.mapSize.height = 512; // default
        // light.shadow.camera.near = 0.5; // default
        // light.shadow.camera.far = 500; // default


        // Camera Positions setup

        camera.position.x = 1;
        camera.position.y = 7.958;
        camera.position.z = 23.562;

        camera.rotation.x = -0.21 * (Math.PI / 180);
        camera.rotation.y = 2.59 * (Math.PI / 180);
        camera.rotation.z = 0 * (Math.PI / 180);


        // Camera Positions setup


        // const controls = new OrbitControls(camera, renderer.domElement);

        const animate = () => {
            requestAnimationFrame(animate);


            renderer.render(scene, camera);
        };


        animate();


    }

    useEffect(() => {
        document.getElementById("category_img").innerHTML = "";
        // setTimeout(function () {
        //     cearteCanvas(ColorChange, PattrenChange);
        // }, 1000);

        cearteCanvas(ColorChange, PattrenChange);

    }, [ColorChange, PattrenChange])
    return (

        <div id="category_img" className="category_img" ref={ref}/>


    );
}

export default HomeTheater;