import React from 'react'
import { Container, Button } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Frame from '../assets/image/logo/Frame.svg';
import Form from 'react-bootstrap/Form';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { FaFacebookF } from 'react-icons/fa'
import { GrTwitter } from 'react-icons/gr'
import { BsInstagram } from 'react-icons/bs'
import { ImYoutube } from 'react-icons/im'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <Container>
                    <Row>
                        <Col className='first_col for_space' xs={12} sm={12} md={12} lg={5} xxl={5}>
                            <div className='image_logo'>
                                <img className='d-block logo_image' src={Frame} />
                            </div>
                            <p className='legal d-inline-block'>Legal</p>
                            <ul className='Privacy d-inline-block'>
                                <li>
                                    Privacy
                                </li>
                            </ul>
                        </Col>

                        <Col className='second_col for_space' xs={12} sm={12} md={12} lg={2} xxl={2}>
                            <ul>
                                <li><h3>Quick Links</h3></li>
                                <li>Home </li>
                                <li>Categories </li>
                                <li> <h3>Customize</h3></li>
                                <li>Inspiration Gallery </li>
                                <li>Our Process </li>
                                <li>Payment Info </li>
                                <li>Resources</li>
                            </ul>
                        </Col>

                        <Col className='third_col for_space' xs={12} sm={12} md={12} lg={3} xxl={3}>
                            <h3 className='contactus'>Contacts</h3>
                            <p className='details'>775 West Concord Park Dr. Bluffdale, UT 84065 </p>
                            <br />
                            <p className='contact'> T.(801) 619-7044</p>
                            <p className='email'>E.sales@onnitsystems.com </p>
                            <div className='icons'>
                                <FaFacebookF className='space' />
                                <GrTwitter className='space' />
                                <BsInstagram className='space' />
                                <ImYoutube className='space' />
                            </div>

                        </Col>

                        <Col className='fourth_col for_space' xs={12} sm={12} md={12} lg={2} xxl={2}>
                            <div>
                                <p className='data'>
                                    Be the first to get new product news
                                    and special offers
                                </p>
                                <br />
                                <div className='inputhere'>
                                    <Form.Control
                                        className='inputcheck'
                                        placeholder="Email"
                                        aria-label="Email"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>

                                <Button className='side_btn'>
                                    Subscribe
                                    <HiOutlineArrowNarrowRight className='icon' />
                                </Button>
                            </div>
                        </Col>
                        <Col className='theend' xs={12} sm={12}>
                            <div className="theend_div d-xl-flex d-lg-flex d-md-flex justify-content-between align-items-center">
                                <p>2022© ONNIT. All rights reserved.</p>
                                <p className=''>Site designed and developed by Tekrevol</p>
                            </div>
                        </Col>

                    </Row>
                </Container>

            </div>

        </footer>
    )
}

export default Footer