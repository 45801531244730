import IMG1 from "../assets/image/colors/1.jpeg";
import IMG2 from "../assets/image/colors/2.jpeg";
import IMG3 from "../assets/image/colors/3.jpeg";
import IMG4 from "../assets/image/colors/4.jpeg";
import IMG5 from "../assets/image/colors/5.jpeg";
import IMG6 from "../assets/image/colors/6.jpeg";
import IMG7 from "../assets/image/colors/7.jpeg";
import IMG8 from "../assets/image/colors/8.jpeg";
import IMG9 from "../assets/image/colors/9.jpeg";
import IMG10 from "../assets/image/colors/10.jpeg";
import IMG11 from "../assets/image/colors/11.jpeg";
import IMG12 from "../assets/image/colors/12.jpeg";
import IMG13 from "../assets/image/colors/13.jpeg";
import IMG14 from "../assets/image/colors/14.jpeg";
import IMG15 from "../assets/image/colors/15.jpeg";
import IMG16 from "../assets/image/colors/16.jpeg";
import IMG17 from "../assets/image/colors/17.jpeg";
import IMG18 from "../assets/image/colors/18.jpeg";
import IMG19 from "../assets/image/colors/19.jpeg";
import IMG20 from "../assets/image/colors/20.jpeg";
import IMG21 from "../assets/image/colors/21.jpeg";
import IMG22 from "../assets/image/colors/22.jpeg";
import IMG23 from "../assets/image/colors/23.jpeg";
import IMG24 from "../assets/image/colors/24.jpeg";
import IMG25 from "../assets/image/colors/25.jpeg";
import IMG26 from "../assets/image/colors/26.jpeg";
import IMG27 from "../assets/image/colors/27.jpeg";
import IMG28 from "../assets/image/colors/28.jpeg";
import IMG29 from "../assets/image/colors/29.jpeg";
import IMG30 from "../assets/image/colors/30.jpeg";
import IMG31 from "../assets/image/colors/31.jpeg";

import DiamondWeaveglof from "../assets/image/selectproduct/golfsimulator/Diamond-Weave.svg";
import Weaveglof from "../assets/image/selectproduct/golfsimulator/Weave.png";
import Stackglof from "../assets/image/selectproduct/golfsimulator/Stack.svg";
import Diamondglof from "../assets/image/selectproduct/golfsimulator/Diamond.svg";
import Slabglof from "../assets/image/selectproduct/golfsimulator/Slab.png";

import Diamond from "../assets/image/selectproduct/hometheater/diamond.png";
import Stack from "../assets/image/selectproduct/hometheater/Stack.png";
import Weave from "../assets/image/selectproduct/hometheater/Weave.png";
import DiamondWeave from "../assets/image/selectproduct/hometheater/Diamond-Weave.png";
import Emmaus from "../assets/image/selectproduct/hometheater/Emmaus.png";
import Adaptablewithbevelededge from "../assets/image/selectproduct/hometheater/Adaptable-with-beveled-edge.png";
import Repeat from "../assets/image/selectproduct/hometheater/Repeat.png";
import Elevate from "../assets/image/selectproduct/hometheater/Elevate.png";
import Timeless from "../assets/image/selectproduct/hometheater/Timeless.png";

// import DiamondWeave from "../assets/image/selectproduct/Diamond-Weave.png";
// import Bubbles from "../assets/image/selectproduct/Bubbles.png";
// import Diamond from "../assets/image/selectproduct/Diamond.png";
// import Elevate from "../assets/image/selectproduct/Elevate.png";
// import Emmaus from "../assets/image/selectproduct/Emmaus.png";
// import Hatch from "../assets/image/selectproduct/Hatch.png";
// import Illusion from "../assets/image/selectproduct/Illusion.png";
// import Repeat from "../assets/image/selectproduct/Repeat.png";
// import Adaptablewithbevelededge from "../assets/image/selectproduct/Adaptable-with-beveled-edge.png";
// import Hexagonslabwithbevelededge from "../assets/image/selectproduct/Hexagon_slab-with-beveled-edge.png";
// import Slab from "../assets/image/selectproduct/Slab.png";
// import Slabwithbevelededge from "../assets/image/selectproduct/Slab-with-beveled-edge.png";
// import squareWithLines from "../assets/image/selectproduct/squareWithLines.png";
// import Stack from "../assets/image/selectproduct/Stack.png";
// import Tile from "../assets/image/selectproduct/Tile.png";
// import Tilt from "../assets/image/selectproduct/Tilt.png";
// import Timeless from "../assets/image/selectproduct/Timeless.png";
// import Weave from "../assets/image/selectproduct/Weave.png";

export const ColorList = [
    {
        image: IMG1,
    }, {
        image: IMG2,
    }
    , {
        image: IMG3,
    }
    , {
        image: IMG4,
    }
    , {
        image: IMG5,
    }
    , {
        image: IMG6,
    }
    , {
        image: IMG7,
    }
    , {
        image: IMG8,
    }
    , {
        image: IMG9,
    }
    , {
        image: IMG10,
    }
    , {
        image: IMG11,
    }
    , {
        image: IMG12,
    }
    , {
        image: IMG13,
    }
    , {
        image: IMG14,
    }
    , {
        image: IMG15,
    }
    , {
        image: IMG16,
    }
    , {
        image: IMG17,
    }
    , {
        image: IMG18,
    }
    , {
        image: IMG19,
    }, {
        image: IMG20,
    }
    , {
        image: IMG21,
    }
    , {
        image: IMG22,
    }
    , {
        image: IMG23,
    }, {
        image: IMG23,
    }, {
        image: IMG24,
    }, {
        image: IMG25,
    }, {
        image: IMG26,
    }, {
        image: IMG27,
    }, {
        image: IMG28,
    }, {
        image: IMG29,
    }, {
        image: IMG30,
    }, {
        image: IMG31,
    }


];
export const productlistHome = [
    {
        image: Diamond,
        info: "Diamond",
    },
    {
        image: Stack,
        info: "Stack",
    },
    {
        image: Timeless,
        info: "Timeless",
    },
    {
        image: Weave,
        info: "Weave",
    },
    {
        image: DiamondWeave,
        info: "Diamond Weave",
    }
    ,
    {
        image: Emmaus,
        info: "Emmaus",
    }
    ,
    {
        image: Adaptablewithbevelededge,
        info: "Adaptable with beveled edge",
    }
    ,
    {
        image: Repeat,
        info: "Repeat",
    },
    {
        image: Elevate,
        info: "Elevate",
    }
    ,
    {
        image: Slabglof,
        info: "Slab",
    }

];
export const productlistGolf = [
    {
        image: DiamondWeaveglof,
        info: "Diamond Weave",
    },
    {
        image: Diamondglof,
        info: "Diamonds",
    },
    {
        image: Stackglof,
        info: "Stack",
    },
    {
        image: Weaveglof,
        info: "Weave",
    }
    ,
    {
        image: Slabglof,
        info: "Slab",
    }

];

