import React from 'react'
import { Container } from 'react-bootstrap'

const Smallbanners = ({ data }) => {
    return (
        <div className='side-image'>
            <img className="img-fluid" src={data.image} alt='' />
            <h3 >{data.info}</h3>
        </div>
    )
}

export default Smallbanners