import React, { Component, useEffect, useRef } from "react";
import * as THREE from "three";
import golfcarpet from '../assets/test/golf-without-carpet.png';
import Golfcourse from '../assets/test/Golf Simulator.jpg';
import carpet from '../assets/test/carpet.jpg';
import baffle from '../assets/test/Baffle.png';
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

function GolfSimulator(props) {

    let ColorChange = props.frontimage;
    let PattrenChange = props.myselectproduct;
    let TileRepeatX = 2;
    let TileRepeatY = 2;
    let RoofTileRepeatX = 3;
    let RoofTileRepeatY = 3;

    const ref = useRef(null);

    const cearteCanvas = (ColorChange, PattrenChange) => {


        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        // var camera = new THREE.OrthographicCamera(  - 2,  2, 2, - 2, 0.1,  1000 );
        // const container = document.getElementById('CanvasFrame');
        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        // container.appendChild(renderer.domElement);
        ref.current.appendChild(renderer.domElement);
        scene.background = new THREE.Color(0x00000);


        const light1 = new THREE.DirectionalLight(0xffffff, 1)
        light1.position.set(2, 2, 5)
        scene.add(light1)

        // wall texture
        const texture = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(TileRepeatX, TileRepeatY);

        });
        texture.generateMipmaps = false;
        // immediately use the texture for material creation
        const material = new THREE.MeshBasicMaterial({ map: texture });
        // material.blending =THREE.MultiplyBlending;
        material.transparent = true;

        const ceilingtexture = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(RoofTileRepeatX, RoofTileRepeatY);

        });
        ceilingtexture.generateMipmaps = false;
        // immediately use the texture for material creation
        const ceilingmaterial = new THREE.MeshBasicMaterial({ map: ceilingtexture });
        // material.blending =THREE.MultiplyBlending;
        ceilingmaterial.transparent = true;


        const texture1 = new THREE.TextureLoader().load(Golfcourse);

        // immediately use the texture for material creation
        const material1 = new THREE.MeshBasicMaterial({ map: texture1 });

        const walltexture = new THREE.TextureLoader().load(ColorChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(5, 5);

        });
        // immediately use the texture for material creation
        const Wallmaterial = new THREE.MeshBasicMaterial({ map: walltexture });


        const Floortexture = new THREE.TextureLoader().load(carpet);
        Floortexture.generateMipmaps = false;
        // immediately use the texture for material creation
        const Floormaterial = new THREE.MeshBasicMaterial({ map: Floortexture });


        const Scenetexture = new THREE.TextureLoader().load(golfcarpet);
        // immediately use the texture for material creation
        const Scenematerial = new THREE.MeshBasicMaterial({ map: Scenetexture });
        Scenematerial.transparent = true;

        const Baffletexture = new THREE.TextureLoader().load(baffle);
        // immediately use the texture for material creation
        const Bafflematerial = new THREE.MeshBasicMaterial({ map: Baffletexture });
        Bafflematerial.transparent = true;


        //  FRONT_SCREEN_GOLF
        const geometry = new THREE.PlaneGeometry(22, 10.4);
        // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
        const GolfScreen = new THREE.Mesh(geometry, material1);
        scene.add(GolfScreen);
        GolfScreen.position.set(0, 5.28, 7.82);


        // Baffle
        var Bafflegeometry = new THREE.PlaneGeometry(21, 1);
        var Baffle = new THREE.Mesh(Bafflegeometry, Bafflematerial);
        scene.add(Baffle);
        Baffle.position.set(0, 9.5, 11.2);


        // LEFTWALL
        var geometryLateral = new THREE.PlaneGeometry(7.9, 10.7);
        var Leftwall = new THREE.Mesh(geometryLateral, Wallmaterial);
        scene.add(Leftwall);
        Leftwall.position.set(-12.940, 5.4, 11.05);
        Leftwall.rotation.set(0, 62.92 * (Math.PI / 180), 0);

        // LEFTWALL_TEXTURE
        var geometryLateral = new THREE.PlaneGeometry(7.9, 10.7);
        var LeftwallTexture = new THREE.Mesh(geometryLateral, material);
        scene.add(LeftwallTexture);
        LeftwallTexture.position.set(-12.940, 5.4, 11.05);
        LeftwallTexture.rotation.set(0, 62.92 * (Math.PI / 180), 0);


        // RIGHTWALL
        var geometryLateral = new THREE.PlaneGeometry(7.9, 10.8);
        var Rightwall = new THREE.Mesh(geometryLateral, Wallmaterial);
        scene.add(Rightwall);
        Rightwall.position.set(12.940, 5.45, 11.05);
        Rightwall.rotation.set(0, -62.92 * (Math.PI / 180), 0);

        // RIGHTWALL_TEXTURE
        var geometryLateral = new THREE.PlaneGeometry(7.9, 10.8);
        var RightwallTexture = new THREE.Mesh(geometryLateral, material);
        scene.add(RightwallTexture);
        RightwallTexture.position.set(12.940, 5.45, 11.05);
        RightwallTexture.rotation.set(0, -62.92 * (Math.PI / 180), 0);

        // CEILING
        var ceilinggeometry = new THREE.PlaneGeometry(30, 7.9);
        var Ceiling = new THREE.Mesh(ceilinggeometry, Wallmaterial);
        scene.add(Ceiling);
        Ceiling.position.set(0, 10.8, 11.05);
        Ceiling.rotation.set(90.00 * (Math.PI / 180), 0, 0);

        // CEILING_TEXTURE
        var ceilinggeometry = new THREE.PlaneGeometry(30, 7.9);
        var CeilingTexture = new THREE.Mesh(ceilinggeometry, ceilingmaterial);
        scene.add(CeilingTexture);
        CeilingTexture.position.set(0, 10.7, 11.05);
        CeilingTexture.rotation.set(90.00 * (Math.PI / 180), 0, 0);




        // // Ceiling Cylinder
        // const Cylindergeometry = new THREE.CylinderGeometry(10.87, 14.95, 7.9, 4, 1);
        // // const Cylindermaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        // const cylinder = new THREE.Mesh(Cylindergeometry, Wallmaterial);
        // scene.add(cylinder);
        // cylinder.scale.set(0, 1, 1);
        // cylinder.position.set(0, 10.7, 11);
        // cylinder.rotation.set(90.00 * (Math.PI / 180), -90.00 * (Math.PI / 180), -180.00 * (Math.PI / 180));


        // // Ceiling Cylinder
     
        
        // const cylindertexture = new THREE.Mesh(Cylindergeometry, ceilingmaterial);
        // scene.add(cylindertexture);
        // cylindertexture.scale.set(0, 1, 1);
        // cylindertexture.position.set(0, 10.7, 11);
        // cylindertexture.rotation.set(90.00 * (Math.PI / 180), -90.00 * (Math.PI / 180), -180.00 * (Math.PI / 180));

        // FLOOR
        var geometryLateral2 = new THREE.PlaneGeometry(30, 30);
        var Floor = new THREE.Mesh(geometryLateral2, Floormaterial);

        scene.add(Floor);
        Floor.position.set(0, 0, 0);
        Floor.rotation.set(-90 * (Math.PI / 180), 0, 0)

        // FRONT_SCREEN
        var Scenegeometry = new THREE.PlaneGeometry(25, 15);
        var Scene = new THREE.Mesh(Scenegeometry, Scenematerial);

        scene.add(Scene);
        Scene.position.set(-0.569, 6.157, 15.000);
        Scene.scale.set(1.57, 1.351, 1.0);


        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(4, 6, 0); //default; directionalLight shining from top
        directionalLight.castShadow = true;
        directionalLight.receiveShadow = false; //default
        scene.add(directionalLight);

        //Create a PointLight and turn on shadows for the light
        const light = new THREE.PointLight(0xffffff, .3, 100);
        light.position.set(0, 10, 4);
        light.castShadow = true; // default false
        scene.add(light);

        //Set up shadow properties for the light
        light.shadow.mapSize.width = 512; // default
        light.shadow.mapSize.height = 512; // default
        light.shadow.camera.near = 0.5; // default
        light.shadow.camera.far = 500; // default

        camera.position.x = -1.0;
        camera.position.y = 4.580;
        camera.position.z = 25.6;

        camera.rotation.x = 0 * (Math.PI / 180);
        camera.rotation.y = 0 * (Math.PI / 180);
        camera.rotation.z = 0 * (Math.PI / 180);


        // const controls = new OrbitControls(camera, renderer.domElement);

        const animate = () => {
            requestAnimationFrame(animate);


            renderer.render(scene, camera);
        };


        animate();
    }
    useEffect(() => {
        document.getElementById("category_img").innerHTML = "";
        // setTimeout(function () {
        //     cearteCanvas(ColorChange, PattrenChange);
        // }, 1000);

        cearteCanvas(ColorChange, PattrenChange);

    }, [ColorChange, PattrenChange])
    return (

        <div id="category_img" className="category_img" ref={ref} />


    );
}

export default GolfSimulator;