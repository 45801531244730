import React, {Component, useEffect, useRef} from "react";
import * as THREE from "three";
import gymmodel from '../assets/gymmodel.glb';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

function HomeGym(props) {

    let ColorChange = props.frontimage;
    let PattrenChange = props.myselectproduct;


    const ref = useRef(null);

    const cearteCanvas = (ColorChange, PattrenChange) => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
        // var camera = new THREE.OrthographicCamera(  - 2,  2, 2, - 2, 0.1,  1000 );
        // const container = document.getElementById('CanvasFrame');
        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        // container.appendChild(renderer.domElement);
        ref.current.appendChild(renderer.domElement);
        scene.background = new THREE.Color(0xCCCDC9);


        const loader = new GLTFLoader()
        loader.load(gymmodel, function (glb) {
            console.log(glb)
            const root = glb.scene;
            root.castShadow = true;
            root.receiveShadow = true;
            scene.add(root);

        }, function (xhr) {
            console.log((xhr.loaded / xhr.total * 100) + "% loaded")
        }, function (error) {
            console.log('ann error')
        })


        // white spotlight shining from the side, casting a shadow
        const spotLight = new THREE.SpotLight(0xffffff);
        spotLight.position.set(40.916, 35.437, -67.685);
        spotLight.castShadow = true;
        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;
        spotLight.shadow.camera.near = 500;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 30;
        scene.add(spotLight);


        const light = new THREE.AmbientLight(0x404040); // soft white light
        scene.add(light);


        const texture = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(2, 2);

        });

        // immediately use the texture for material creation
        const material = new THREE.MeshBasicMaterial({map: texture});
        // material.blending =THREE.MultiplyBlending;
        material.transparent = true;


        const lefttexture = new THREE.TextureLoader().load(PattrenChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(1, 2);

        });

        // immediately use the texture for material creation
        const leftwallmaterial = new THREE.MeshBasicMaterial({map: lefttexture});
        leftwallmaterial.transparent = true;


        const walltexture = new THREE.TextureLoader().load(ColorChange, function (texture) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(4, 4);

        });

        // immediately use the texture for material creation
        const Wallmaterial = new THREE.MeshBasicMaterial({map: walltexture});


        //  FRONT_Wall
        const geometry = new THREE.BoxGeometry(14, 10, 0.40);
        // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
        const Frontwall = new THREE.Mesh(geometry, Wallmaterial);
        scene.add(Frontwall);
        Frontwall.position.set(2.946, 5.00, -5.00);
        // FRONT_Wall_TEXTURE
        var geometryLateral = new THREE.PlaneGeometry(14, 10);
        var FrontwallTexture = new THREE.Mesh(geometryLateral, material);
        scene.add(FrontwallTexture);
        FrontwallTexture.position.set(2.946, 5.00, -4.78);


        // FRONT_LEFTWALL
        var geometryLateral = new THREE.BoxGeometry(3, 10, 0.40);
        var Leftwall = new THREE.Mesh(geometryLateral, Wallmaterial);
        scene.add(Leftwall);
        Leftwall.position.set(-8.500, 5.000, -5.000);


        // FRONT_LEFTWALL_TEXTURE
        var geometryLateral = new THREE.PlaneGeometry(3, 10);
        var LeftwallTexture = new THREE.Mesh(geometryLateral, leftwallmaterial);
        scene.add(LeftwallTexture);
        LeftwallTexture.position.set(-8.500, 5.000, -4.78);


        // RIGHTWALL
        var geometryLateral = new THREE.BoxGeometry(12.00, 10, 0.40);
        var Rightwall = new THREE.Mesh(geometryLateral, Wallmaterial);
        scene.add(Rightwall);
        Rightwall.position.set(10.144, 5.000, 0.810);
        Rightwall.rotation.set(0, 90 * (Math.PI / 180), 0);

        // RIGHTWALL_TEXTURE
        var geometryLateral = new THREE.PlaneGeometry(12.00, 10);
        var RightwallTexture = new THREE.Mesh(geometryLateral, material);
        scene.add(RightwallTexture);
        RightwallTexture.position.set(9.93, 5.000, 0.810);
        RightwallTexture.rotation.set(0, -90 * (Math.PI / 180), 0);


        camera.position.x = -0.760;
        camera.position.y = 16.347;
        camera.position.z = 11.649;

        camera.rotation.x = -41.40 * (Math.PI / 180);
        camera.rotation.y = 0 * (Math.PI / 180);
        camera.rotation.z = 0 * (Math.PI / 180);


        // const controls = new OrbitControls(camera, renderer.domElement);

        const animate = () => {
            requestAnimationFrame(animate);


            renderer.render(scene, camera);
        };


        animate();

    }
    useEffect(() => {
        document.getElementById("category_img").innerHTML = "";
        // setTimeout(function () {
        //     cearteCanvas(ColorChange, PattrenChange);
        // }, 1000);

        cearteCanvas(ColorChange, PattrenChange);

    }, [ColorChange, PattrenChange])
    return (

        <div id="category_img" className="category_img" ref={ref}/>


    );
}

export default HomeGym;