import React, {useState, useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ColorList, productlistHome} from "../app/constant";
import {RiArrowDropDownLine} from "react-icons/ri";
import FrontWall from "../assets/image/WallSelection/FrontWall.png";
import LeftWall from "../assets/image/WallSelection/LeftWall.png";
import RightWall from "../assets/image/WallSelection/RightWall.png";
import Celling from "../assets/image/WallSelection/Celling.png";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {HiOutlineArrowNarrowRight} from "react-icons/hi";
import download from "../assets/image/icons/download.png";
import HomeGallery from "../assets/image/roomdecorationpic/HomeGallery.png";
import Stack from "../assets/image/selectproduct/hometheater/Stack.png";
import FirstImg from "../assets/image/colors/1.jpeg";

export const Walls = (props) => {
    const slug = props.slug;

    return (
        <div className="category_img" style={{
            backgroundImage: `url("${props.frontimage}")`,
            backgroundRepeat: "repeat",
            backgroundSize: `86px 175px`
        }}>
            <img src={HomeGallery} alt="Gym-img" className="img-fluid main_img"/>

            <div className="pattern_change" style={{
                backgroundImage: `url("${props.myselectproduct}")`,
                backgroundRepeat: "repeat",
                backgroundSize: "86px 175px",
            }}>

            </div>
        </div>
    );
};


const LetCustomizeYourWall = (props) => {
    const [FrontColors, setFrontColors] = useState(FirstImg);
    const [Sproduct, setproduct] = useState(Stack);

    const colorFix = (image) => {
        setFrontColors(image);
    };
    const imageFix = (image) => {
        setproduct(image);
    };

    return (
        <div className="custom_Sound_Pannel LetsCustomizeYourWall">
            <div className="container">
                <h2>Let’s Customize Your Walls</h2>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                        <div className="choose_here">
                            <Link to="/category/hometheater" className={"link_choice"}>
                                Home Theater
                            </Link>
                            <Link to="/category/homegolf" className={"link_choice"}>
                                Home Golf Simulator
                            </Link>
                            <Link to="/category/homegym" className={"link_choice"}>
                                Home Gym Setup
                            </Link>
                            <Link to="/category/homeoffice" className={"link_choice"}>
                                Home Office
                            </Link>
                        </div>

                        <Walls
                            frontimage={FrontColors}
                            myselectproduct={Sproduct}
                        />
                    </Col>
                    <Col
                        xs={4}
                        sm={4}
                        md={2}
                        lg={1}
                        xl={1}
                        xxl={1}
                        className="WallSelection"
                    >
                        <img src={FrontWall}/>
                        <p className="mt-2">FrontWall</p>
                        <img src={LeftWall}/>
                        <p className="mt-2">LeftWall</p>
                        <img src={RightWall}/>
                        <p className="mt-2">RightWall</p>
                        <img src={Celling}/>
                        <p className="mt-2">Celling</p>
                    </Col>
                    <Col
                        xs={{span: 6, offset: 2}}
                        sm={{span: 6, offset: 2}}
                        md={6}
                        lg={3}
                        xl={3}
                        xxl={3}
                        className="color_selection offset-lg-1 offset-md-2"
                    >
                        <Row>
                            <div className="Choose_color mt-3">
                                <p>Pick a Color</p>
                                <div className="column_color color_mainbox for_scroll">
                                    <ul>
                                        {ColorList.map((color) => (
                                            <li
                                                onClick={() => colorFix(color.image)}>
                                                <img src={color.image}/>

                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Row>
                        <Row className="mt-3 content_here">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p>Select a Product</p>
                            </Col>
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                                className=" V-Groov"
                            >
                                V-Groove Etched Panels
                                <RiArrowDropDownLine className="V-Groov-icon"/>
                            </Col>
                        </Row>
                        <Row className="column_color select_products_short">
                            <ul>
                                {productlistHome.map((img) => (
                                    <li onClick={() => imageFix(img.image)}>
                                        <img src={img.image} className="img-fluid"/>
                                    </li>
                                ))}
                            </ul>
                        </Row>
                    </Col>
                </Row>
                <div className="button_and_icon">
                    <Button>
                        Request For Quote

                    </Button>
                    <div>
                        <img src={download}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LetCustomizeYourWall;
