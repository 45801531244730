import React, {useState} from "react";
import {NavDropdown, Button} from "react-bootstrap";
import {NavLink, Link} from "react-router-dom";
import Frame from "../assets/image/logo/Frame.png";
import {HiOutlineArrowNarrowRight} from "react-icons/hi";
import {GiHamburgerMenu} from "react-icons/gi";

const Secondheader = ({slug}) => {
    // navbar code
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <div className="secondheader">
            <div className="container">
                <nav className="navbar">
                    <div className="nav-container">
                        <NavLink exact to="/" className="nav-logo">
                            <img src={Frame} alt="logo"/>
                            <i className="fas fa-code"></i>
                        </NavLink>

                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavDropdown
                                    className="distance dropdown "
                                    title="Categories"
                                    id="collasible-nav-dropdown"
                                >
                                    <NavLink to="/Categories" className={"dropdown-item"}>
                                        Categories
                                    </NavLink>

                                    <NavLink to="/category/hometheater" className="dropdown-item">
                                        Home Theater
                                    </NavLink>

                                    <NavLink to="/category/homegym" className="dropdown-item">
                                        Home Gym Setup
                                    </NavLink>

                                    <NavLink to="/category/homegolf" className="dropdown-item">
                                        Home Golf Simulator
                                    </NavLink>
                                    <NavLink to="/category/homeoffice" className="dropdown-item">
                                        Home Office
                                    </NavLink>
                                </NavDropdown>
                            </li>

                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/contactus"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    ContactUs
                                </NavLink>
                            </li>
                            <li>
                                <Link to="/Letscustomizeyourwall">
                                    <Button className="btnarrow nav-item">
                                        Customize <HiOutlineArrowNarrowRight/>
                                    </Button>
                                </Link>
                            </li>
                        </ul>

                        <div className="nav-icon" onClick={handleClick}>
                            <GiHamburgerMenu/>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Secondheader;
