import React from "react";
import Home from "./app/Home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import CateGories from "./app/Categoriespage";
import Contactus from "./app/Contactus";
import HomeTheaterGallery from "./app/HomeTheaterGallery";
import LetsCustomizeYourWall from "./app/LetsCustomizeYourWall";


const App = () => {
    return (
        <BrowserRouter>
            < Routes>
                <Route path='/' element={< Home/>}/>
                <Route path='/Categories' element={< CateGories/>}/>
                <Route path='/Contactus' element={< Contactus/>}/>
                <Route path='/category/:slug' element={< HomeTheaterGallery/>}/>
                <Route path='/Letscustomizeyourwall' element={< LetsCustomizeYourWall/>}/>
            </Routes>
        </BrowserRouter>

    );
};

export default App;
