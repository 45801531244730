import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import firstimage from "../assets/image/categories/1.png";
import secondimage from "../assets/image/categories/2.png";
import thirdimage from "../assets/image/categories/3.png";

const IndividualIntervalsExample = (props) => {
  return (
    <Carousel>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 image-fluid"
          src={props.firstimage}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img
          className="d-block w-100 image-fluid"
          src={props.secondimage}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 image-fluid"
          src={props.thirdimage}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

// right - content & left image component

const Categories = (props) => {
  return (
    <div className="contentwithimage">
      <Container className="container">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="side-image">
              <IndividualIntervalsExample
                className="image_slider"
                firstimage={firstimage}
                secondimage={secondimage}
                thirdimage={firstimage}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={{ span: 5, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
          >
            <div className="side-content mt-5">
              <h1 className="heading_content">{props.heading}</h1>
              <p className="para_content">{props.content}</p>
              <div className="btn_content">
                <Button className="btn_content">
                  {props.buttontext}
                  <HiOutlineArrowNarrowRight className="icon" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// left - content & right-image component
export const NextCategories = (props) => {
  return (
    <div className="contentwithimage">
      <Container className="container">
        <Row>
          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 5, order: 1 }}
            xl={{ span: 5, order: 1 }}
          >
            <div className="side-content mt-5">
              <h1 className="heading_content">{props.heading}</h1>
              <p className="para_content">{props.content}</p>
              <div className="btn_content">
                <Button className="btn_content">
                  {props.buttontext}
                  <HiOutlineArrowNarrowRight className="icon" />
                </Button>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2, offset: 1 }}
            xl={{ span: 6, order: 2, offset: 1 }}
          >
            <div className="side-image">
              <IndividualIntervalsExample
                className="image_slider"
                firstimage={firstimage}
                secondimage={secondimage}
                thirdimage={firstimage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Categories;
