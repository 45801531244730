import React from "react";
import Form from "react-bootstrap/Form";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaFacebookF } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import { ImYoutube } from "react-icons/im";
import { useForm } from "react-hook-form";

const CustomForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Form.Group
          className="mb-3 for_input"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Control
            type="email"
            placeholder="Email*"
            className={`form-control ${errors.email && "invalid"}`}
            {...register("email", {
              required: "Email is Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            onKeyUp={() => {
              trigger("email");
            }}
          />
          {errors.email && (
            <small className="text-danger">{errors.email.message}</small>
          )}
        </Form.Group>

        <Form.Group
          className="mb-3 for_input"
          as={Col}
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          xxl="6"
          controlId="validationCustom01"
        >
          <Form.Control
            required
            type="text"
            placeholder="Your Name*"
            className={`form-control ${errors.name && "invalid"}`}
            {...register("name", { required: "Name is Required" })}
            onKeyUp={() => {
              trigger("name");
            }}
          />
          {errors.name && (
            <small className="text-danger">{errors.name.message}</small>
          )}
        </Form.Group>
        <Form.Group
          className="mb-3 for_input"
          as={Col}
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          xxl="6"
          controlId="validationCustom02"
        >
          <Form.Control
            required
            type="text"
            placeholder="Phone Number*"
            className={`form-control ${errors.phone && "invalid"}`}
            {...register("phone", {
              required: "Phone is Required",
              pattern: {
                value:
                  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                message: "Invalid phone no",
              },
            })}
            onKeyUp={() => {
              trigger("phone");
            }}
          />
          {errors.phone && (
            <small className="text-danger">{errors.phone.message}</small>
          )}
        </Form.Group>
        <Form.Group
          className="mb-3 for_input"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control as="textarea" rows={3} placeholder="Email*" />
        </Form.Group>
      </Row>
    </Form>
  );
};

const Contactuscom = () => {
  return (
    <div className="contactus">
      <div className="container">
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <div className="heading">
              <h1>Contact Us</h1>
              <h3>Get in touch, we’re user-friendly!</h3>
            </div>
            <div className="custom_form">
              <CustomForm />
            </div>
            <div className="btn_content">
              <Button>
                Submit
                <HiOutlineArrowNarrowRight className="icon" />
              </Button>
            </div>
          </Col>

          <Col
            className="side_content_contactus"
            xs={12}
            sm={12}
            md={12}
            lg={{ span: 3, offset: 1 }}
            xl={{ span: 2, offset: 1 }}
            xxl={{ span: 2, offset: 1 }}
          >
            <h3 className="imformation">Information</h3>
            <h4 className="Address">Address</h4>
            <p className="content">
              775 West Concord Park Dr. Bluffdale, UT 84065, United States
            </p>
            <h4 className="connect Address">Connect with us</h4>
            <div className="icons">
              <GrTwitter className="space" />
              <FaFacebookF className="space" />
              <BsInstagram className="space" />
              <ImYoutube className="space" />
            </div>
            <h4 className="Technical Address">Technical Support</h4>
            <div>
              <p className="content">
                Please contact us at:
                <br />
                (801) 619-7044
              </p>
              <p>
                <span>sales@onnitsystems.com</span>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contactuscom;
