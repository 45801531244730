import React from "react";
import Customizewall from "../components/Customizewall";
import Consultationimg from "../assets/image/bannarimages/b1.png";
import Topheader from "../components/Topheader";
import Secondheader from "../components/secondheader";
import Footer from "../components/Footer";
import {useParams} from "react-router-dom";

const HomeTheaterGallery = (props) => {
    const {slug} = useParams();

    return (
        <>
            <Topheader/>
            <Secondheader/>
            <Customizewall slug={slug}/>
            <Footer/>
        </>
    );
};
export default HomeTheaterGallery;