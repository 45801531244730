import React from "react";
import { Container, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { AiOutlineLine } from "react-icons/ai";
import left from "../assets/image/icons/left.png";
import right from "../assets/image/icons/right.png";

const Testimonials = (props) => {
  return (
    <div className="testimonials">
      <Container className="container">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xxl={6}>
            <div className="first_side_content">
              <p className="top_left_heading"> Testimonials</p>
              <h1 className="top_left_para">
                What our clients are saying about us
              </h1>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={{ span: 5, offset: 1 }}
            xxl={{ span: 5, offset: 1 }}
          >
            <div className="Second_side_content">
              <p className="top_right_heading">Smalls & Co. Design</p>
              <p className="top_right_para">
                “The ONNiT Systems team were fantastic at bringing our vision to
                life. We had ONNIT install Acoustical Sound Panels in my
                client's home theater. The client wanted something that would
                offer sound dampening while being co hesive with the rest of the
                design elements. The ONNiT team were able to take our ideas and
                integrate them in the panels. They were easy to communicate
                with, listened to and really understood our concept, and their
                execution was flawless. I am thankful for them bringing our
                designs to life.”
              </p>
              <AiOutlineLine className="top_right_icon" />
              <p className="top_right_end_para">
                Randi Smith - Principal Designer & Owner
              </p>
              <Row>
                <Col xs={2} sm={2} md={2} lg={2} xxl={2}>
                  <img src={left} />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xxl={2}>
                  <img src={right} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;
