import React from "react";
import Categories from "../components/Categories";
import {NextCategories} from "../components/Categories";
import Consultation from '../components/Consultation'
import Consultationimg from '../assets/image/bannarimages/b1.png'
import Topheader from '../components/Topheader'
import Footer from '../components/Footer'
import Secondheader from "../components/secondheader";

const CateGories = () => {
    return (
        <>
            <Topheader/>
            <Secondheader/>
            <Categories heading='Treasure these moments with your family in your home theater experience'
                        content='ONNiT Systems Felt Acoustical Wall Panels will help soundproof your home theater by reducing Ambient Echo to bring crisp clear, high-quality sound to your theater experience. Our acoustical panels are elegant and uniquely designed to bring added value to your home.'
                        buttontext='Patterns & Colors'/>
            <NextCategories heading='Take Your Home Golf Simulator to the Next Level'
                            content='YES!  You can have the perfect look and functionality with the ONNiT Systems golf simulator sound absorbing protection wall panels. If you want it ALL, YOU found it! Our superior sound absorbing wall panels bring stylish elegance and protection as an all-in-one bonus. Choose any of our 31 colors and multiple patterns to compliment your décor and bring added value to your investment. Have your own design in mind?  Great! We can do that too!  '
                            buttontext='Patterns & Colors'/>
            <Categories heading='Get the most out of your home gym setup'
                        content='Increase the fun by eliminating the unwanted reverberating sound and change a “loud zone” into the “THE FUN ZONE”.  ONNiT Systems high quality- high impact sound absorbing wall panels are very durable.  Our geometric panel shapes offer you endless possibilities to create mathematical magic on the walls'
                        buttontext='Patterns & Colors'/>
            <NextCategories heading='Some dummy text to be replaced with the original for your home office'
                            content='This is dummy. It is not meant to be read. It is placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. But then, this is dummy copy. It is not meant to be read. Period.'
                            buttontext='Patterns & Colors'/>
            <Consultation image={Consultationimg} heading='Schedule A Free Consultation'
                          title={`We would love to discuss your project.`}
                          subtitle={'Please get in touch'}
                          btn='Get in Touch'/>
            <Footer/>
        </>
    )
}
export default CateGories