import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Button } from "react-bootstrap";

const Consultation = (props) => {
  return (
    <div className="consultation container">
      <div
        className="image overlay"
        style={{
          backgroundImage: `linear-gradient(259.13deg, rgba(0, 0, 0, 0) 17.64%, rgba(0, 0, 0, 0.5) 49.38%), url("${props.image}")`,
        }}
      >
        <div className="over"></div>
        <div className="imgtopheading">
          <p className="tophead">{props.heading}</p>
          <p className="tittle">{props.title}</p>
          <p className="subtittle">{props.subtitle}</p>
          <Button>
            {props.btn}
            <HiOutlineArrowNarrowRight className="icon" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
